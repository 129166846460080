<template>
  <div class="error">
    <div class="error-body">
      <div class="error-text-container">
        <h1 class="error-title">
          403
        </h1>
        <h2 class="error-title">
          К сожалению, в доступе отказано
        </h2>
        <p class="error-p">
          Возможно, страница недоступна или
          отсутствуют разрешения открывать её. Обратитесь за необходимыми учётными данными к администратору.
          Чтобы новые учётные данные вступили в силу, попробуйте снова войти в систему.
        </p>
      </div>
      <div class="error-btn">
        <b-button
          variant="light"
          size="sm"
          class="p-2"
          :type="$const.PRIMARY_BUTTON"
          @click="onLoginPageOpen"
        >
          войти в систему
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',
  methods: {
    onLoginPageOpen() {
      this.$store.dispatch(this.$types.LOGOUT);
    },
  },
};
</script>
<style lang="scss" scoped>
.error {
    display: flex;
    width: 100%;
    height: 100vh;
    background: white;
}
.error-body {
  display: flex;
  flex-direction: column;
  background: rgb(250, 249, 249);
  border-radius: 10px;
  width: 60%;
  min-height: 50%;
  margin: auto;
  @media (max-width: 576px) {
    width: 100%;
    padding: 0 20px;
  }
}
.error-text-container {
  padding: 100px 50px ;
}

.error-title {
  font-weight: bold;
  line-height: 44px;
  color: #036bcc;
  text-align: left;
}

h1 {
  font-size: 50px;
}

h2 {
  font-size: 30px;
}
.error-p {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #616161;
  text-align: left;
}
.error-btn{
  margin: auto auto 30px;
}
</style>
